'use strict';

/**
 * @ngdoc function
 * @name informaApp.directive:infDateRangePicker
 * @description
 * # infDateRangePicker
 * Directive of the informaApp
 */
angular.module('informaApp')
    .directive('infDateRangePicker', function () {
        return{
            restrict: 'E',
            templateUrl: 'directives/inf-dateRangePicker/template.ptl.html',

            scope:{
                options: '='
            },

            htmlInputDateFormat: "YYYY-MM-DD",

            emmitOnChange: function(options, element){
                if (typeof options.onChange === 'function'){
                    var startDate = moment(this.getStartDateInput(element).val(),this.htmlInputDateFormat).toDate();
                    var endDate = moment(this.getEndDateInput(element).val(),this.htmlInputDateFormat).toDate();

                    options.onChange(startDate, endDate);
                }
            },
            emmitOnHide: function (options) {
                if (typeof options.onHide === 'function'){
                    options.onHide();
                }
            },
            getStartDateInput: function(element){
                return element.find('input.js-start-date');
            },
            getEndDateInput: function(element){
                return element.find('input.js-end-date');
            },

            setInputDate: function(element, date){
                var htmlInputDate = moment(date).format(this.htmlInputDateFormat);

                if (element.val() !== htmlInputDate){
                    element.val(htmlInputDate);
                }
            },
            setDefaultsValue: function(element, options, dateName){
                var date = options.defaults[dateName];

                if (date){
                    this.setInputDate(element, date);

                    options.datePickerOptions[dateName] = date;
                }
            },
            bindDateRangePickerEvents: function(options, element){
                var datePicker = element.find('.picker');

                var sender = this;
                datePicker.on('apply.daterangepicker', function(e, p){
                    sender.setInputDate(sender.getStartDateInput(element), p.startDate.toDate());
                    sender.setInputDate(sender.getEndDateInput(element), p.endDate.toDate());

                    sender.emmitOnChange(options, element);
                });
                datePicker.on('hide.daterangepicker', function () {
                    sender.emmitOnHide(options);
                });
            },
            configureDateRangePicker: function(options, element){
                var datePicker = element.find('.picker');
                element.find('.picker').attr("readonly", true);

                options.datePickerOptions.autoApply = true;

                datePicker.daterangepicker(options.datePickerOptions);
            },
            changeValue: function(options, element, startDate, endDate){
                options.defaults = {
                    startDate: startDate,
                    endDate: endDate
                };

                this.configure(options, element);
                this.bindDateRangePickerEvents(options, element);
            },
            bindMobileInputsEvents: function(options, element){
                var startDate = this.getStartDateInput(element);
                var endDate = this.getEndDateInput(element);

                var sender = this;

                var bind = function(e){
                    sender.changeValue(options, element,
                        moment(startDate.val(), sender.htmlInputDateFormat),
                        moment(endDate.val(), sender.htmlInputDateFormat));

                    sender.emmitOnChange(options, element);
                };

                startDate.on('change', bind);
                endDate.on('change', bind);
            },
            configure: function(options, element){
                if (options.defaults){
                    this.setDefaultsValue(this.getStartDateInput(element), options, 'startDate');
                    this.setDefaultsValue(this.getEndDateInput(element), options, 'endDate');
                }

                this.configureDateRangePicker(options, element);
            },
            bindEvents: function(options, element){
                this.bindMobileInputsEvents(options, element);
                this.bindDateRangePickerEvents(options, element);
            },
            configureOptions: function (options) {
                var defaults = {
                    datePickerOptions: {
                        hideRanges: true,
                        showDropdowns: true,
                        changeDayWithMonthYear: true,
                        stickyDay: true,
                    }
                };

                return _.merge(defaults, options);
            },
            link: function(scope, element, attr){
                var options =  this.configureOptions(scope.options);
                var sender = this;
                scope.$watch('options', function (newValue, oldValue) {
                    var newOptions = sender.configureOptions(newValue);

                    sender.configure(newOptions, element);
                    sender.bindDateRangePickerEvents(newOptions, element);
                });

                this.configure(options, element);
                this.bindEvents(options, element);
            }
        }
    });
